import React from "react";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Pinterest from "@mui/icons-material/Pinterest";
import Instagram from "@mui/icons-material/Instagram";
import Link from "next/link";
import { RouteNamesExternal } from "../../../constants/routeNamesExternal";
import footerStyles from "../../../styles/Footer.module.css";

const DesktopLinks = () => {
  const handleMemberClick = async () => {
    await window.analytics.track("Become a Member Clicked");
  };

  return (
    <div className={footerStyles.desktopLinksContainer}>
      <div className={footerStyles.flexACEC}>
        <div style={{ paddingRight: "60px" }}>
          <h4 style={{ color: "black" }}>
            ABOUT{" "}
            <hr
              style={{
                width: "25px",
                margin: "10px 0 0 0",
                color: "rgb(244, 244, 244)",
              }}
            />
          </h4>
          <ul>
            <li className={footerStyles.linkWrap}>
              <Link href={RouteNamesExternal.ATD_ABOUT_US}>About Us</Link>
            </li>
            <li className={footerStyles.linkWrap}>
              <Link href={RouteNamesExternal.HELP_FAQ}>FAQs</Link>
            </li>
            <li className={footerStyles.linkWrap}>
              <Link href={RouteNamesExternal.ATD_ENTERPRISE_HOME}>
                Enterprise Solutions
              </Link>
            </li>
            <li className={footerStyles.linkWrap}>
              <Link href={RouteNamesExternal.ATD_CHAPTERS}>Chapters</Link>
            </li>
            <li className={footerStyles.linkWrap}>
              <Link href={RouteNamesExternal.ATD_GLOBAL_HOME}>ATD Global</Link>
            </li>
            <li className={footerStyles.linkWrap}>
              <Link href={RouteNamesExternal.ATD_PRESS_ROOM}>
                ATD Press Room
              </Link>
            </li>
            <li className={footerStyles.linkWrap}>
              <Link href={RouteNamesExternal.ATD_STORE}>Store</Link>
            </li>
            <li className={footerStyles.linkWrap}>
              <Link href={RouteNamesExternal.ATD_ADVERTISE}>
                Advertise With Us
              </Link>
            </li>
          </ul>
        </div>
        <div className={footerStyles.flexCEC}>
          <div style={{ paddingRight: "50px" }}>
            <h4 style={{ color: "black" }}>
              CAREERS{" "}
              <hr
                style={{
                  width: "25px",
                  margin: "10px 0 0 0",
                  color: "rgb(244, 244, 244)",
                }}
              />
            </h4>
            <ul>
              <li className={footerStyles.linkWrap}>
                <Link href={RouteNamesExternal.ATD_CAREERS}>
                  Careers at ATD
                </Link>
              </li>
              <li className={footerStyles.linkWrap}>
                <Link href={RouteNamesExternal.JOBS_BANK}>ATD Job Bank</Link>
              </li>
            </ul>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingRight: "50px",
            }}
          >
            <div>
              <h4 style={{ color: "black" }}>
                EMAIL US{" "}
                <hr
                  style={{
                    width: "25px",
                    margin: "10px 0 0 0",
                    color: "rgb(244, 244, 244)",
                  }}
                />
              </h4>
              <ul>
                <li className={footerStyles.linkWrap}>
                  <Link href={RouteNamesExternal.ATD_MAIL_CUSTOMER_CARE}>
                    customercare@td.org
                  </Link>
                </li>
              </ul>
            </div>
            <ul>
              <h4 style={{ color: "black" }}>
                CALL US{" "}
                <hr
                  style={{
                    width: "25px",
                    margin: "10px 0 0 0",
                    color: "rgb(244, 244, 244)",
                  }}
                />
              </h4>
              <li className={footerStyles.linkWrap}>
                <Link href={RouteNamesExternal.ATD_PHONE_GLOBAL}>
                  +1.800.628.2783
                </Link>
              </li>
              <li className={footerStyles.linkWrap}>
                <Link href={RouteNamesExternal.ATD_PHONE_US}>
                  +1.703.683.8100
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div style={{ paddingRight: "50px" }}>
        <h4 style={{ color: "black" }}>
          STAY CONNECTED{" "}
          <hr
            style={{
              width: "25px",
              margin: "10px 0 0 0",
              color: "rgb(244, 244, 244)",
            }}
          />
        </h4>
        <div style={{ paddingBottom: "15px" }}>
          <Link href={RouteNamesExternal.SOCIAL_FACEBOOK}>
            <FacebookIcon style={{ padding: "5px" }} fontSize="large" />
          </Link>
          <Link href={RouteNamesExternal.SOCIAL_TWITTER}>
            <TwitterIcon style={{ padding: "5px" }} fontSize="large" />
          </Link>
          <Link href={RouteNamesExternal.SOCIAL_LINKEDIN}>
            <LinkedInIcon style={{ padding: "5px" }} fontSize="large" />
          </Link>
          <Link href={RouteNamesExternal.SOCIAL_PINTEREST}>
            <Pinterest style={{ padding: "5px" }} fontSize="large" />
          </Link>
          <Link href={RouteNamesExternal.SOCIAL_INSTAGRAM}>
            <Instagram style={{ padding: "5px" }} fontSize="large" />
          </Link>
        </div>
        <button className={footerStyles.memberButton}>
          <Link href={RouteNamesExternal.CHECKOUT_MEMBERSHIP}>
            <a onClick={() => handleMemberClick()}>Become A Member</a>
          </Link>
        </button>
      </div>
    </div>
  );
};

export default DesktopLinks;
