import React, { useEffect, useState } from "react";
import { createStyles } from "@material-ui/core/styles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import addDays from "date-fns/addDays";
import { getCookie, setCookie } from "cookies-next";
import { Typography } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import { Button, Grid } from "@mui/material";
import strings from "../../constants/strings";
import { RouteNamesExternal } from "../../constants/routeNamesExternal";

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      fontFamily: "whitney-book",
      color: "#313133",
      lineHeight: 1,
      boxSizing: "border-box",
      position: "fixed",
      width: "100%",
      border: "none",
      backgroundColor: "rgba(0,0,0,0.85)",
      minHeight: "50px",
      minWidth: "280px",
      zIndex: 999999,
      bottom: "0px",
    },
    content: {
      boxSizing: "border-box",
      background: "none",
      border: "none",
      bottom: "auto",
      clear: "none",
      float: "none",
      overflow: "visible",
      right: "auto",
      textAlign: "left",
      textDecoration: "none",
      textIndent: "0",
      fontFamily:
        "Trebuchet MS, Lucida Sans Unicode, Lucida Grande, Lucida Sans, Arial, sans-serif",
      textTransform: "none",
      textShadow: "none",
      top: "auto",
      visibility: "visible",
      whiteSpace: "normal",
      width: "auto",
      padding: "0",
      margin: "0",
      borderRadius: "0",
      position: "static",
      fontWeight: "normal",
      zIndex: 999999,
      fontSize: "14px",
      lineHeight: "16pt",
      color: "#FFFFFF !important",
      marginLeft: "10px",
      "& a": {
        color: "#FF4D00",
      },
    },
    alertBox: {
      color: "#313133",
      boxSizing: "border-box",
      background: "none",
      border: "none",
      bottom: "auto",
      clear: "none",
      float: "none",
      left: "auto",
      letterSpacing: "normal",
      lineHeight: "normal",
      maxHeight: "none",
      maxWidth: "none",
      minHeight: 0,
      minWidth: 0,
      overflow: "visible",
      right: "auto",
      textAlign: "left",
      textDecoration: "none",
      textIndent: 0,
      textTransform: "none",
      textShadow: "none",
      top: "auto",
      visibility: "visible",
      whiteSpace: "normal",
      width: "auto",
      padding: 0,
      margin: 0,
      borderRadius: 0,
      position: "static",
      fontWeight: "normal",
      zIndex: 999999,
      height: "10px",
    },
    box: {
      padding: "10px",
    },
    closeWrapper: {
      color: "#313133",
      boxSizing: "border-box",
      fontFamily: "Arial, sans-serif",
      background: "none",
      border: "none",
      bottom: "auto",
      clear: "none",
      float: "none",
      left: "45px",
      letterSpacing: "normal",
      lineHeight: "normal",
      maxHeight: "none",
      maxWidth: "none",
      minHeight: "0",
      minWidth: "0",
      overflow: "visible",
      textAlign: "left",
      textDecoration: "none",
      textIndent: "0",
      textTransform: "none",
      textShadow: "none",
      visibility: "visible",
      whiteSpace: "normal",
      padding: "0",
      margin: "0",
      borderRadius: "0",
      fontWeight: "normal",
      zIndex: 999999,
      top: "10%",
      position: "relative",
    },
    closeBtn: {
      boxSizing: "border-box",
      color: "inherit",
      font: "inherit",
      fontFamily:
        "Trebuchet MS, Lucida Sans Unicode, Lucida Grande, Lucida Sans, Arial, sans-serif",
      border: "none",
      clear: "none",
      letterSpacing: "normal",
      lineHeight: "normal",
      maxHeight: "none",
      maxWidth: "none",
      overflow: "visible",
      textDecoration: "none",
      textTransform: "none",
      textShadow: "none",
      visibility: "visible",
      whiteSpace: "normal",
      fontWeight: "normal",
      zIndex: 999999,
      background:
        'url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDc0IDc0IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA3NCA3NDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4NCgkuc3Qwe2ZpbGw6I0NDQ0NDQzt9DQoJLnN0MXtmaWxsOiNGRkZGRkY7fQ0KCS5zdDJ7ZmlsbDojNjY2NjY2O30NCjwvc3R5bGU+DQo8Zz4NCgk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzcsNzEuNWMtOS42LDAtMTcuOC0zLjQtMjQuNC0xMC4xQzUuOCw1NC40LDIuNSw0Ni41LDIuNSwzN2MwLTkuNiwzLjQtMTcuOCwxMC4xLTI0LjQNCgkJQzE5LjIsNS45LDI3LjQsMi41LDM3LDIuNWM5LjYsMCwxNy44LDMuNCwyNC40LDEwLjFjNi44LDYuOSwxMC4xLDE0LjksMTAuMSwyNC40YzAsOS42LTMuNCwxNy44LTEwLjEsMjQuNA0KCQlDNTQuNCw2OC4yLDQ2LjUsNzEuNSwzNyw3MS41eiIvPg0KCTxwYXRoIGNsYXNzPSJzdDEiIGQ9Ik0zNyw1YzguOSwwLDE2LjQsMy4xLDIyLjYsOS40QzY1LjgsMjAuNyw2OSwyOC4xLDY5LDM3cy0zLjEsMTYuNC05LjQsMjIuNkM1My4zLDY1LjgsNDUuOSw2OSwzNyw2OQ0KCQlzLTE2LjQtMy4xLTIyLjYtOS40UzUsNDUuOSw1LDM3czMuMS0xNi40LDkuNC0yMi42QzIwLjYsOC4xLDI4LjEsNSwzNyw1IE0zNywwQzI2LjgsMCwxOCwzLjcsMTAuOSwxMC45QzMuNywxOCwwLDI2LjgsMCwzNw0KCQljMCwxMCwzLjYsMTguOCwxMC44LDI2LjFDMTcuOSw3MC4zLDI2LjgsNzQsMzcsNzRjMTAsMCwxOC44LTMuNiwyNi4xLTEwLjhDNzAuMyw1Ni4xLDc0LDQ3LjIsNzQsMzdjMC0xMC0zLjYtMTguOC0xMC44LTI2LjENCgkJQzU2LjEsMy43LDQ3LjIsMCwzNywwTDM3LDB6Ii8+DQo8L2c+DQo8cGF0aCBjbGFzcz0ic3QyIiBkPSJNNTIuNCw0Ny43TDQxLjcsMzdsMTAuOS0xMC43YzAuNi0wLjYsMC42LTEuMSwwLTEuN2wtMy4xLTNjLTAuMy0wLjMtMC42LTAuNC0wLjktMC40Yy0wLjIsMC0wLjQsMC4xLTAuNywwLjQNCglMMzcsMzIuMUwyNi4xLDIxLjZjLTAuMy0wLjMtMC41LTAuNC0wLjctMC40Yy0wLjMsMC0wLjYsMC4xLTAuOSwwLjRsLTMsM2MtMC42LDAuNi0wLjYsMS4xLDAsMS43TDMyLjQsMzdMMjEuNiw0Ny45DQoJYy0wLjIsMC4xLTAuMywwLjMtMC4zLDAuN3MwLjEsMC43LDAuMywwLjlsMywzLjFjMC4yLDAuMiwwLjUsMC4zLDAuOSwwLjNzMC43LTAuMSwwLjktMC4zTDM3LDQxLjdsMTAuOSwxMC43DQoJYzAuMywwLjMsMC41LDAuNCwwLjcsMC40YzAuMywwLDAuNi0wLjEsMC45LTAuNGwzLTNjMC4zLTAuMiwwLjQtMC41LDAuNC0wLjlDNTIuOSw0OC4zLDUyLjcsNDgsNTIuNCw0Ny43eiIvPg0KPC9zdmc+")',
      backgroundSize: "22px 22px",
      width: "22px",
      height: "22px",
      cursor: "pointer",
    },
    button: {
      display: "flex",
    },
  })
);

const CookieBanner = () => {
  /**********************
   *  STATE
   ***********************/
  const [isShown, setisShown] = useState(false);
  const optanon = getCookie("OptanonAlertBoxClosed");

  /**********************
   *  HOOKS
   ***********************/
  const classes = useStyles();
  useEffect(() => {
    if (optanon === undefined) {
      setisShown(true);
    }
  }, [optanon]);

  /**********************
   *  FUNCTIONS
   ***********************/
  const onAlertClose = () => {
    setCookie("OptanonAlertBoxClosed", new Date().toDateString(), {
      path: "/",
      expires: addDays(new Date(), 365),
      //domain: "localhost",
    });
    //change/set domain of cookie when new url is set
    setisShown(false);
  };

  /**********************
   *  RENDER
   ***********************/
  return (
    <Slide direction="up" in={isShown} mountOnEnter unmountOnExit>
      <div className={classes.wrapper}>
        <Grid className={classes.box} container direction="row">
          <Grid md={8} sm={12} xs={12} item>
            <Typography className={classes.content}>
              {strings.COOKIE_BANNER.DESCRIPTION}{" "}
              <a href={RouteNamesExternal.ATD_COOKIE_POLICY}>
                {strings.COOKIE_BANNER.CTA}
              </a>
            </Typography>
          </Grid>
          <Grid md={3} xs={10} item style={{ paddingLeft: "10px" }}>
            <Button
              onClick={onAlertClose}
              variant="contained"
              style={{
                backgroundColor: "#e13000",
                fontFamily:
                  "Trebuchet MS, Lucida Sans Unicode, Lucida Grande, Lucida Sans, Arial, sans-serif",
              }}
            >
              Accept Cookies
            </Button>
          </Grid>
          <Grid className={classes.button} md={1} xs={2} item>
            <div className={classes.closeWrapper}>
              <button onClick={onAlertClose} className={classes.closeBtn} />
            </div>
          </Grid>
        </Grid>
      </div>
    </Slide>
  );
};

export default CookieBanner;
