import { useState, useEffect, useRef } from "react";

const useComponentShow = (show) => {
  const [isComponentShow, setIsComponentShow] = useState(!show);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (
      ref.current &&
      !ref.current.contains(event.target) &&
      event.target.nodeName.indexOf("BUTTON") === -1
    ) {
      setIsComponentShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return { ref, isComponentShow, setIsComponentShow };
};

export default useComponentShow;
