import "../styles/globals.css";
import Footer from "../components/Footer";
import React, { useState } from "react";
import Head from "next/head";
import Navigation from "../components/NavigationHomePage";
import { CheckProvider } from "../hooks/useContext";
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "../services/msal";
import Banner from "../components/Banner";
import CookieBanner from "../components/CookieBanner";

const App = ({ Component, pageProps }) => {
  const segmentScript = `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="HhXyk4KyVPvMC91PoiL7lYfhT5W0lRwK";;analytics.SNIPPET_VERSION="4.15.3";analytics.load("HhXyk4KyVPvMC91PoiL7lYfhT5W0lRwK");analytics.page();}}();`;
  const ga4Script = `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-7K8E29WLBJ');`;
  const [newsletterHeader, setNewsletterHeader] = useState("");
  const [closeBanner, setCloseBanner] = useState(false);

  return (
    <>
      <CheckProvider>
        <Head>
          <title>Talent Brief | ATD</title>
          <link rel="shortcut icon" href="/images/atd-logo-small.svg" />
          <script>{segmentScript}</script>
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-7K8E29WLBJ"
          ></script>
          <script>{ga4Script}</script>
          <style>{newsletterHeader}</style>
        </Head>
        <MsalProvider instance={msalInstance}>
          {closeBanner ? null : <Banner close={setCloseBanner} />}
          <Navigation />
          <Component {...pageProps} headState={setNewsletterHeader} />
        </MsalProvider>
        <Footer />
        <CookieBanner />
      </CheckProvider>
    </>
  );
};

export default App;
