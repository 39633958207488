import React from "react";
import bannerStyles from "../../styles/Banner.module.css";

const Banner = ({ close }) => {
  return (
    <div className={bannerStyles.banner}>
      <p>
        Welcome to Talent Brief, a weekly ATD newsletter, that brings you
        current research, big ideas, and helpful tips from the talent
        development industry. We curate the latest TD trends every Tuesday
        morning, rain or shine.
        <br />
        <br />
        <span>
          <button onClick={() => close(true)} className={bannerStyles.close}>
            close
          </button>
        </span>
      </p>
    </div>
  );
};

export default Banner;
