import Link from "next/link";
import navStyles from "../../styles/Nav.module.css";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Snackbar from "@mui/material/Snackbar";
import React, { useState } from "react";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import useComponentShow from "../../hooks/useComponentShow";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MenuIcon from "@mui/icons-material/Menu";
import DesktopMenu from "../DesktopMenu";
import useWindowSize from "../../hooks/useResize";
import MobileMenu from "../MobileMenu";
import * as Sentry from "@sentry/nextjs";
import Image from "next/image";
import blackAtdLogo from "../../public/images/blackAtd.png";
import tbLogo from "../../public/images/tbLogo.png";

const Navigation = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [startTask, setStartTask] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [count, setCount] = useState(0);
  const { instance, accounts } = useMsal();
  const { ref, isComponentShow, setIsComponentShow } = useComponentShow(true);
  const resize = useWindowSize();

  const getLastInitial = (modifyName) => {
    if (modifyName.indexOf(" ") !== -1) {
      const lastNameIndex = modifyName.indexOf(" ") + 1;
      return modifyName[lastNameIndex];
    }
  };

  const increase = () => {
    setCount(count + 1);
  };

  const brazeCampaignAlgolia = () => {
    setStartTask(true);
    setLoading(true);
    setOpen(true);
    if (count === 0) {
      axios
        .get("/api/getCampaignListTask")
        .then(
          (resp) => (
            console.log("BRAZE-ALGOLIA_TASK", resp.data.res),
            setLoading(false),
            setCount(0)
          )
        )
        .catch((err) => {
          setStartTask(false);
          setLoading(false);
          setOpen(false);
          setCount(0);
          Sentry.captureException(err);
        });
    }
  };

  const toggleDrawer = () => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenDrawer(!openDrawer);
  };

  const list = () => (
    <Box
      sx={{ width: "280px" }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <MobileMenu
        mobileStates={{
          loading,
          setLoading,
          open,
          setOpen,
          startTask,
          setStartTask,
          instance,
          accounts,
          ref,
          isComponentShow,
          setIsComponentShow,
          getLastInitial,
          increase,
          brazeCampaignAlgolia,
        }}
      />
    </Box>
  );

  return (
    <div className={navStyles.header}>
      <div className={navStyles.headerContent}>
        <div>
          <Link href="/">
            <a>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "baseline",
                }}
              >
                <div>
                  <Image
                    src={tbLogo}
                    alt="tb-logo"
                    width={
                      resize > 780 ? "290px" : resize < 330 ? "190px" : "290px"
                    }
                    height={
                      resize > 780 ? "60px" : resize < 330 ? "40px" : "60px"
                    }
                  />
                </div>
              </div>
            </a>
          </Link>
        </div>
        {resize > 780 ? (
          <DesktopMenu
            desktopStates={{
              loading,
              setLoading,
              open,
              setOpen,
              startTask,
              setStartTask,
              instance,
              accounts,
              ref,
              isComponentShow,
              setIsComponentShow,
              getLastInitial,
              increase,
              brazeCampaignAlgolia,
            }}
          />
        ) : (
          <MenuIcon
            sx={{ cursor: "pointer", paddingRight: "25px" }}
            fontSize={resize < 330 ? "small" : "normal"}
            onClick={() => setOpenDrawer(true)}
          />
        )}
      </div>
      {openDrawer ? (
        <Drawer anchor="right" open={openDrawer} onClose={toggleDrawer(false)}>
          {list("right")}
        </Drawer>
      ) : null}
      {startTask ? (
        loading ? (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            open={open}
          >
            <div className={navStyles.taskProgress}>
              <div className={navStyles.taskText}>
                brazeAlgolia task inProgress
              </div>
              <div>&nbsp;</div>
              <div className={navStyles.loader}>
                <CircularProgress color="warning" size="2rem" />
              </div>
            </div>
          </Snackbar>
        ) : (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            open={open}
            onClose={() => setOpen(false)}
          >
            <div className={navStyles.taskProgressComplete}>
              <div className={navStyles.taskText}>
                brazeAlgolia task complete
              </div>
              <div>&nbsp;</div>
              <div>
                <CheckCircleIcon
                  sx={{ paddingTop: "6px", paddingLeft: "6px" }}
                  fontSize="large"
                />
              </div>
            </div>
          </Snackbar>
        )
      ) : null}
    </div>
  );
};

export default Navigation;
