import { createContext, useState } from "react";

const CheckContext = createContext();

const CheckProvider = (props) => {
  const [algoliaIndex, setAlgoliaIndex] = useState("talentBrief-newsletter");
  return (
    <CheckContext.Provider value={{ algoliaIndex, setAlgoliaIndex }}>
      {props.children}
    </CheckContext.Provider>
  );
};

export { CheckContext, CheckProvider };
