import React from "react";
import Link from "next/link";
import DesktopLinks from "./DesktopLinks";
import { RouteNamesExternal } from "../../constants/routeNamesExternal";
import strings from "../../constants/strings";
import Image from "next/image";
import footerStyles from "../../styles/Footer.module.css";
import atdLogo from "../../public/images/atd-logo-small.svg";

const Footer = () => {
  return (
    <div className={footerStyles.footer}>
      <div className={footerStyles.logoWrap}>
        <p className={footerStyles.svgWrap}>
          <Image
            onClick={() => {
              window.location.replace(RouteNamesExternal.ATD_HOME);
            }}
            src={atdLogo}
            alt="ATD"
          />
        </p>
        <p className={footerStyles.msWrap}>{strings.MISSION.DESCRIPTION}</p>
      </div>
      <DesktopLinks />
      <div className={footerStyles.lastFooterChild}>
        <div>
          <span style={{ color: "black" }}>
            &copy; {new Date().getFullYear()} ATD | All Rights Reserved
          </span>
          <p style={{ fontSize: "9.5px" }}>
            ASTD changed its name to ATD to meet the growing needs of a dynamic,
            <br />
            global profession.
          </p>
        </div>
        <div>&nbsp;</div>
        <div className={footerStyles.privacyUse}>
          <Link href={RouteNamesExternal.ATD_TOS}>Terms of Use</Link>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <Link href={RouteNamesExternal.ATD_TOS_PRIVACY}>Privacy Notice</Link>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <Link href={RouteNamesExternal.ATD_COOKIE_POLICY}>Cookie Policy</Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
