import React from "react";
import Avatar from "@mui/material/Avatar";
import navStyles from "../../styles/Nav.module.css";
import Link from "next/link";
import { deepOrange } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const DesktopMenu = ({ desktopStates }) => {
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      padding: "10px",
      fontSize: "12px",
    },
  }));

  return (
    <div
      className={
        desktopStates.accounts.length === 0
          ? navStyles.subHeader
          : navStyles.subHeaderAfterLogin
      }
    >
      <div className={navStyles.subscribe}>
        <Link
          href={{
            pathname: "/subscribe",
          }}
        >
          Subscribe
        </Link>
      </div>
      <div>&nbsp;</div>
      <div>&nbsp;</div>
      <div>
        {desktopStates.accounts.length === 0 ? (
          <button
            className={navStyles.adminLogin}
            onClick={() => desktopStates.instance.loginRedirect()}
          >
            Admin Login
          </button>
        ) : (
          <div className={navStyles.avatarDropdown}>
            <Avatar
              className={navStyles.avatar}
              sx={{
                bgcolor: "#e23325",
              }}
              variant="square"
              onClick={() =>
                desktopStates.setIsComponentShow(!desktopStates.isComponentShow)
              }
              ref={desktopStates.ref}
            >
              {desktopStates.accounts[0].name[0]}
              {desktopStates.getLastInitial(desktopStates.accounts[0].name)}
            </Avatar>
            {desktopStates.isComponentShow ? (
              <div className={navStyles.dropdownOptions}>
                <p className={navStyles.navName}>
                  Admin: {desktopStates.accounts[0].name}
                </p>
                <div>
                  <BootstrapTooltip title="Task ingests braze campaigns to algolia">
                    <button
                      className={navStyles.task}
                      onClick={() => {
                        desktopStates.brazeCampaignAlgolia();
                        desktopStates.increase();
                      }}
                    >
                      brazeAlgolia Task
                    </button>
                  </BootstrapTooltip>
                </div>
                <div>
                  <button
                    className={navStyles.logout}
                    onClick={() => desktopStates.instance.logoutRedirect()}
                  >
                    Logout
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default DesktopMenu;
