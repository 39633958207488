import React from "react";
import Avatar from "@mui/material/Avatar";
import navStyles from "../../styles/Nav.module.css";
import Link from "next/link";
import { deepOrange } from "@mui/material/colors";

const MobileMenu = ({ mobileStates }) => {
  return (
    <div
      className={
        mobileStates.accounts.length === 0
          ? navStyles.subHeaderMobile
          : navStyles.subHeaderAfterLoginMobile
      }
    >
      <div>
        {mobileStates.accounts.length === 0 ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className={navStyles.headerMobile}>
              <Link href="/">
                <a>
                  T<span style={{ color: "#e23325" }}>B</span>
                </a>
              </Link>
            </div>
            <div>
              <button
                className={navStyles.adminLoginMobile}
                onClick={() => mobileStates.instance.loginRedirect()}
              >
                Admin Login
              </button>
            </div>
            <Link
              href={{
                pathname: "/subscribe",
              }}
            >
              <div className={navStyles.subscribeMobile}>Subscribe</div>
            </Link>
          </div>
        ) : (
          <div>
            <div className={navStyles.avatarDropdownMobile}>
              <Avatar
                className={navStyles.avatarMobile}
                sx={{
                  bgcolor: "#e23325",
                  cursor: "pointer",
                }}
                variant="circular"
                ref={mobileStates.ref}
              >
                {mobileStates.accounts[0].name[0]}
                {mobileStates.getLastInitial(mobileStates.accounts[0].name)}
              </Avatar>
              <div>&nbsp;</div>
              <div>&nbsp;</div>
              <div>
                <p className={navStyles.navName}>
                  Admin: {mobileStates.accounts[0].name}
                </p>
              </div>
            </div>
            <div className={navStyles.dropdownOptionsMobile}>
              <Link
                href={{
                  pathname: "/subscribe",
                }}
              >
                <div className={navStyles.subscribeLoginMobile}>Subscribe</div>
              </Link>
              <div>
                <button
                  className={navStyles.taskMobile}
                  onClick={() => {
                    mobileStates.brazeCampaignAlgolia();
                    mobileStates.increase();
                  }}
                >
                  brazeAlgolia Task
                </button>
              </div>
              <div>
                <button
                  className={navStyles.logoutMobile}
                  onClick={() => mobileStates.instance.logoutRedirect()}
                >
                  Logout
                </button>
              </div>
            </div>
          </div>
        )}
        <div className={navStyles.footerMobile}>
          &copy; {new Date().getFullYear()} ATD | All Rights Reserved
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
